//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { documentLableAdd } from '@/api/modular/fileStream/documentLabelManage'
export default {
    data() {
        return {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 15 },
            },
            form: this.$form.createForm(this),
        }
    },
    methods: {
        /**
         * 提交表單
         */
        handleSubmit() {
            const {
                form: { validateFields },
            } = this
            validateFields((errors, values) => {
                if (!errors) {
                    for (const key in values) {
                        if (typeof values[key] === 'object') {
                            values[key] = JSON.stringify(values[key])
                        }
                    }
                    documentLableAdd(values).then((res) => {
                        if (res.success) {
                            this.$message.success('新增成功')
                            this.$router.go(-1)
                        } else {
                            this.$message.error('新增失敗') // + res.message
                        }
                    })
                }
            })
        },
    },
}
